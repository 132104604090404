function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* eslint-disable no-debugger */
import window from '../sdk/windowWrapper';
import imageTokenHelper from '../store/imageTokenHelper';
import { baseUtils } from '../utils/baseUtils';
var WIX_MEDIA_PREFIX = 'https://static.wixstatic.com/media/';

var isExternalUrl = function isExternalUrl(url) {
  var isFullUrl = /(^https?)|(^data)|(^blob)/.test(url);
  var isWixMedia = url.indexOf(WIX_MEDIA_PREFIX) === 0;
  var isResizePrevented = url.indexOf('preventResize') > 0;
  return isFullUrl && (!isWixMedia || isResizePrevented);
};

var isPosterImage = function isPosterImage(item) {
  if (item && item.dto && item.dto.metaData) {
    var type = item.dto.metaData.type;
    var poster = item.poster;

    if (type === 'video' && poster) {
      return true;
    }
  }

  return false;
};

var prefixUrlIfNeeded = function prefixUrlIfNeeded(originalUrl) {
  if (isExternalUrl(originalUrl)) {
    return originalUrl;
  } else {
    return WIX_MEDIA_PREFIX + originalUrl;
  }
};

var removeResizeParams = function removeResizeParams(originalUrl) {
  var isResizePrevented = originalUrl.indexOf('preventResize') >= 0;
  if (isResizePrevented) return originalUrl;
  originalUrl = originalUrl.replace(WIX_MEDIA_PREFIX, '');
  var resizingParamerterRegex = /(\/v\d\/(fill|fit|crop)\/(((w|h|x|y|scl|al|q)_[cf\d]*)\,?)*){1,}/;
  var resizingParametersPosition = resizingParamerterRegex.exec(originalUrl);

  if (resizingParametersPosition && resizingParametersPosition.index > 0) {
    return originalUrl.substr(0, resizingParametersPosition.index);
  } else {
    return originalUrl;
  }
};

var createResizedVideoUrl = function createResizedVideoUrl(_ref) {
  var item = _ref.item,
      originalUrl = _ref.originalUrl,
      requiredHeight = _ref.requiredHeight;
  var videoUrl = originalUrl;

  if (item.qualities && item.qualities.length) {
    var suffix = '/';
    var mp4Qualities = item.qualities.filter(function (video) {
      return video.formats[0] === 'mp4';
    }); // search for the first quality bigger that the required one

    if (mp4Qualities.length > 1 && mp4Qualities[0].height > mp4Qualities[1].height) {
      // some have reversed quality order. not sure how or when this happened
      mp4Qualities.reverse();
    } // eslint-disable-next-line no-cond-assign


    for (var quality, q = 0; quality = mp4Qualities[q]; q++) {
      if (quality.height >= requiredHeight || !mp4Qualities[q + 1]) {
        suffix += quality.quality; // e.g. 720p
        // eslint-disable-next-line no-cond-assign

        for (var format, i = 0; format = quality.formats[i]; i++) {
          videoUrl = window.location.protocol + '//video.wixstatic.com/video/' + item.url + suffix + '/' + format + '/file.' + format;
        }

        break;
      }
    }

    return videoUrl;
  }
};

var createResizedImageUrl = function createResizedImageUrl(_ref2) {
  var item = _ref2.item,
      originalUrl = _ref2.originalUrl,
      resizeMethod = _ref2.resizeMethod,
      requiredWidth = _ref2.requiredWidth,
      requiredHeight = _ref2.requiredHeight,
      _ref2$sharpParams = _ref2.sharpParams,
      sharpParams = _ref2$sharpParams === void 0 ? {} : _ref2$sharpParams,
      _ref2$focalPoint = _ref2.focalPoint,
      focalPoint = _ref2$focalPoint === void 0 ? [0.5, 0.5] : _ref2$focalPoint,
      _ref2$useWebp = _ref2.useWebp,
      useWebp = _ref2$useWebp === void 0 ? false : _ref2$useWebp,
      _ref2$devicePixelRati = _ref2.devicePixelRatio,
      devicePixelRatio = _ref2$devicePixelRati === void 0 ? 1 : _ref2$devicePixelRati;

  var isTransparent = function isTransparent() {
    return originalUrl.indexOf('.png') > 0 || originalUrl.indexOf('.gif') > 0;
  };

  var addSharpParams = function addSharpParams() {
    sharpParams = sharpParams || {};

    if (!sharpParams.quality) {
      sharpParams.quality = 90;
    } // don't allow quality above 90 till we have proper UI indication


    sharpParams.quality = Math.min(90, sharpParams.quality);

    if (sharpParams.allowUsm === true) {
      sharpParams.usm.usm_a = Math.min(5, Math.max(0, sharpParams.usm.usm_a || 0));
      sharpParams.usm.usm_r = Math.min(128, Math.max(0, sharpParams.usm.usm_r || 0)); // should be max 500 - but it's returning a 404

      sharpParams.usm.usm_t = Math.min(1, Math.max(0, sharpParams.usm.usm_t || 0));
    }

    var retUrl = '';
    retUrl += ',q_' + sharpParams.quality;

    if (sharpParams.blur && !isTransparent()) {
      // the blur looks bad in pngs
      retUrl += ',blur_' + sharpParams.blur;
    }

    retUrl += sharpParams.usm && sharpParams.usm.usm_r ? ',usm_' + sharpParams.usm.usm_r.toFixed(2) + '_' + sharpParams.usm.usm_a.toFixed(2) + '_' + sharpParams.usm.usm_t.toFixed(2) : '';
    return retUrl;
  };

  var calcCropParams = function calcCropParams() {
    var scale;
    var x;
    var y;
    var orgW;
    var orgH;
    var maxHeight;
    var maxWidth;

    if (isPosterImage(item) && typeof item.poster === 'object') {
      maxHeight = item.poster.height;
      maxWidth = item.poster.width;
    } else {
      maxHeight = item.maxHeight;
      maxWidth = item.maxWidth;
    }

    var requiredRatio = requiredWidth / requiredHeight;
    var itemRatio = maxWidth / maxHeight; // find the scale

    if (itemRatio > requiredRatio) {
      // wide image (relative to required ratio
      scale = requiredHeight / maxHeight;
      orgW = Math.floor(requiredHeight * itemRatio);
      y = 0;
      x = Math.round(orgW * focalPoint[0] - requiredWidth / 2);
      x = Math.min(orgW - requiredWidth, x);
      x = Math.max(0, x);
    } else {
      // narrow image
      scale = requiredWidth / maxWidth;
      orgH = Math.floor(requiredWidth / itemRatio);
      x = 0;
      y = Math.round(orgH * focalPoint[1] - requiredHeight / 2);
      y = Math.min(orgH - requiredHeight, y);
      y = Math.max(0, y);
    } // make sure scale is not lower than needed
    // scale must be higher to prevent cases that there will be white margins (or 404)


    scale = (Math.ceil(scale * 100) / 100).toFixed(2);
    return {
      x: x,
      y: y,
      scale: scale
    };
  };

  var addResizeParams = function addResizeParams() {
    if (!focalPoint || focalPoint.every(function (f) {
      return f === 0.5;
    })) {
      resizeMethod = resizeMethod === 'fit' ? 'fit' : 'fill';
      if (requiredHeight <= 1 && requiredWidth <= 1) resizeMethod = 'fill';
      return "/v1/" + resizeMethod + "/w_" + requiredWidth + ",h_" + requiredHeight;
    } else {
      var fpStr = focalPoint.map(function (fp) {
        return String(Math.floor(fp * 100) / 100).slice(0, 4);
      }).join('_');
      return "/v1/fill/w_" + requiredWidth + ",h_" + requiredHeight + ",fp_" + fpStr; // example: https://static.wixstatic.com/media/d97dd6_4c4d9f9cb4ae49db8da0a7ec384a2b6c~mv2.jpg/v1/fill/w_278,h_278,fp_0.52_0.46,q_90/d97dd6_4c4d9f9cb4ae49db8da0a7ec384a2b6c~mv2.webp
      // const { x, y, scale } = calcCropParams(
      //   item,
      //   requiredWidth,
      //   requiredHeight,
      //   focalPoint,
      // );
      // return `/v1/crop/w_${requiredWidth},h_${requiredHeight},x_${x},y_${y},scl_${scale}`;
    }
  };

  var addFilename = function addFilename() {
    return '/' + (useWebp ? originalUrl.replace(/[^.]\w*$/, 'webp') : originalUrl).match(/[^/][\w.~]*$/)[0];
  };

  var mobileScaleFix = Math.max(baseUtils.isMobile() && !baseUtils.isSSR() ? window.screen.width / 320 : 1, 1);
  requiredWidth = Math.ceil(requiredWidth * devicePixelRatio * mobileScaleFix);
  requiredHeight = Math.ceil(requiredHeight * devicePixelRatio * mobileScaleFix);
  var retUrl = prefixUrlIfNeeded(originalUrl);
  retUrl += addResizeParams();
  retUrl += addSharpParams();
  retUrl += addFilename();
  retUrl = imageTokenHelper.addToken(retUrl, item);
  return retUrl;
};

var getMediaType = function getMediaType(originalUrl) {
  return {
    jpg: 'jpeg',
    jpeg: 'jpeg',
    png: 'png',
    gif: 'gif'
  }[originalUrl.match(/[^\.]\w*$/)[0]] || 'jpeg';
};

var resizeMediaUrl = function resizeMediaUrl(_ref3) {
  var item = _ref3.item,
      originalUrl = _ref3.originalUrl,
      resizeMethod = _ref3.resizeMethod,
      requiredWidth = _ref3.requiredWidth,
      requiredHeight = _ref3.requiredHeight,
      sharpParams = _ref3.sharpParams,
      focalPoint = _ref3.focalPoint,
      createMultiple = _ref3.createMultiple,
      imageToken = _ref3.imageToken;
  var hasImageToken = item.dto.imageToken || item.dto.token || imageToken;
  originalUrl = removeResizeParams(originalUrl);
  var params = {
    item: item,
    originalUrl: originalUrl,
    resizeMethod: resizeMethod,
    requiredWidth: requiredWidth,
    requiredHeight: requiredHeight,
    sharpParams: sharpParams,
    focalPoint: focalPoint
  };

  if (resizeMethod === 'video') {
    return createResizedVideoUrl(params);
  } else if (isExternalUrl(originalUrl)) {
    return originalUrl;
  } else if (resizeMethod === 'full' && !hasImageToken) {
    return prefixUrlIfNeeded(originalUrl);
  } else if (createMultiple) {
    return [{
      type: 'webp',
      url: createResizedImageUrl(_objectSpread(_objectSpread({}, params), {}, {
        useWebp: true,
        devicePixelRatio: 1
      })),
      dpr: [1, 2].map(function (dpr) {
        return createResizedImageUrl(_objectSpread(_objectSpread({}, params), {}, {
          useWebp: true,
          devicePixelRatio: dpr
        })) + (" " + dpr + "x");
      }).join(', ')
    }, {
      type: getMediaType(originalUrl),
      url: createResizedImageUrl(_objectSpread(_objectSpread({}, params), {}, {
        useWebp: false,
        devicePixelRatio: 1
      })),
      dpr: [1, 2].map(function (dpr) {
        return createResizedImageUrl(_objectSpread(_objectSpread({}, params), {}, {
          useWebp: false,
          devicePixelRatio: dpr
        })) + (" " + dpr + "x");
      }).join(', ')
    }];
  } else {
    return createResizedImageUrl(params);
  }
};

var resizeMediaUrlWithWatermark = function resizeMediaUrlWithWatermark(imageToken) {
  return function (args) {
    return resizeMediaUrl(_objectSpread(_objectSpread({}, args), {}, {
      imageToken: imageToken
    }));
  };
};

export { resizeMediaUrl, resizeMediaUrlWithWatermark };